import { FC, useState } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '@hooks/useRedux';
import {
    getAllQuestions,
    getBrandApiKey,
    getBrandDetails,
    getBrandId,
    getEmailValidation,
    getMobileValidation,
    getNpsRatings,
    getStepSurveyQuestions,
    getTotalSteps,
} from '@redux/v4-survey/selector';

import { ContactInfoValues } from '@models/brand-details';
import { formatQuesRes } from '@utils/fromat-qustion-reponse';
import { formatSurveyAnswers } from '@utils/get-detailed-answer';
import { ImageInfo, SelectedMediaType } from '@models/media';
import { updateCurrentStep } from '@redux/v4-survey/slice';
import { useNavigate } from 'react-router-dom';
import PoweredByTellofy from './UI/PoweredByTellofy';
import { UI_TEXT } from '@constants/ui-text';
import { showError } from '@utils/toast-alerts';
import { TEXT } from '@constants/messages';

const { GROOVY_API_ERROR } = TEXT;

const {
    CONTACT_US,
    FULL_NAME,
    EMAIL_ADDRESS,
    PHONE_NUMBER,
    I_AGREE,
    DATA_POLICY,
    AND,
    TERMS_OF_USE,
    SUBMIT,
} = UI_TEXT;

const PHONE_NUMBER_REGEX = /^[0-9]{10}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

interface ContactInfoProps {
    images: ImageInfo[];
    audioBlob: Blob | null;
    videoBlob: Blob | null;
    selectedMediaType: SelectedMediaType;
    convertedTextFromSpeech: boolean;
}

const ContactInfo: FC<ContactInfoProps> = ({
    images,
    audioBlob,
    videoBlob,
    selectedMediaType,
}) => {
    const stepSurveyQuestions = useAppSelector(getStepSurveyQuestions);
    const isEmailRequired = useAppSelector(getEmailValidation);
    const isMobileRequired = useAppSelector(getMobileValidation);
    const detailedQuestions = useAppSelector(getAllQuestions);
    const brandDetails = useAppSelector(getBrandDetails);
    const totalSteps = useAppSelector(getTotalSteps);
    const npsRatings = useAppSelector(getNpsRatings);
    const [loader, setLoader] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const brandId = useAppSelector(getBrandId);
    const brandApiKey = useAppSelector(getBrandApiKey);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        name: Yup.string()
            .trim()
            .required('Name is required')
            .min(3, 'Please enter at least 3 characters.'),

        email: Yup.string()
            .trim()
            .matches(EMAIL_REGEX, 'Invalid email address')
            .when('isEmailRequired', {
                is: 'true',
                then: (schema) => schema.required('Email is required'),
            }),

        phone: Yup.string()
            .trim()
            .matches(PHONE_NUMBER_REGEX, 'Please enter 10 digits')
            .when('isPhoneRequired', {
                is: 'true',
                then: (schema) =>
                    schema
                        .required('Phone number is required')
                        .min(10, 'Please enter 10 digits')
                        .max(10, 'Please enter 10 digits'),
            }),
        agreeToPolicy: Yup.boolean()
            .required('You must agree to the Data Policy and Terms of Use')
            .oneOf(
                [true],
                'You must agree to the Data Policy and Terms of Use'
            ),
    });

    const handlePhoneInput = (e, setFieldValue) => {
        const input = e.target.value.replace(/\D/g, '');
        if (input.length <= 10) {
            setFieldValue('phone', input);
        }
    };

    const handleRedirect = () => {
        navigate('/thankyou');
    };

    const getMediaType = () => {
        // return selectedMediaType === ?? 'text_new';
        if (selectedMediaType === 'video') {
            return 'video';
        }
        if (selectedMediaType === 'image') {
            return 'image';
        }
        if (selectedMediaType === 'audio') {
            return 'audio';
        }

        return 'text_new';
    };

    const postSurveyDataToGroovyApi = async (dataToPost, mediaType: string) => {
        const url = `${process.env.REACT_APP_POST_REVIEW_API_URL}`;

        const formData = new FormData();

        formData.append('ReviewJson', JSON.stringify(dataToPost));

        try {
            setUploadProgress(10);
            const intervalId = setInterval(() => {
                setUploadProgress((prevProgress) => {
                    if (prevProgress < 90) {
                        return prevProgress + 10; // Increment progress
                    } else {
                        clearInterval(intervalId); // Clear interval at 95%
                        return prevProgress;
                    }

                    // return prevProgress;
                });
                if (uploadProgress >= 95) {
                    clearInterval(intervalId);
                }
            }, 1000);

            setLoader(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `${brandDetails.apiKey}`,
                },
                body: formData,
            });

            if (!response.ok) {
                // If the HTTP status code is not 200-299,
                // throw an error with the status text
                throw new Error(
                    `HTTP error! status: ${response.status} - ${response.statusText}`
                );
            }

            const responseData = await response.json(); // Parse JSON data from the response

            // check the type of multimedia and submit the multimedia .
            if (responseData) {
                if (mediaType === 'audio') {
                    await postAudioToGroovy(responseData.id);
                }
                if (mediaType === 'video') {
                    await postVideoToGroovy(responseData.id);
                }
                if (mediaType === 'image') {
                    await postImagesToGroovy(responseData.id);
                }
                setUploadProgress(100);
                setLoader(false);

                clearInterval(intervalId);
                return handleRedirect();
            }
        } catch (error) {
            // Log error to the console and re-throw it if you need to handle it later
            showError(GROOVY_API_ERROR);
        }
    };

    const postAudioToGroovy = async (reviewId: string) => {
        try {
            const url = `${process.env.REACT_APP_POST_AUDIO_API_URL}?reviewID=${reviewId}&brandRefID=${brandId}&apiKey=${brandApiKey}&source=1`;

            const formData = new FormData();
            formData.append('fname', 'videoRecording_1720251929034.blob');
            formData.append('uploadedfile', audioBlob);

            const audioUploadRes = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `${brandApiKey}`,
                },
                body: formData,
            });

            return audioUploadRes;
        } catch (error) {
            showError(error.message);
        }
    };

    const postVideoToGroovy = async (reviewId: string) => {
        try {
            const url = `${process.env.REACT_APP_POST_VIDEO_API_URL}?reviewID=${reviewId}&brandRefID=${brandId}&apiKey=${brandApiKey}&source=1`;

            const formData = new FormData();
            formData.append('fname', 'videoRecording_1720251929034.blob');
            formData.append('uploadedfile', videoBlob);

            const videoUploadRes = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `${brandApiKey}`,
                },
                body: formData,
            });

            return videoUploadRes;
        } catch (error) {
            showError(GROOVY_API_ERROR);
        }
    };

    const postImagesToGroovy = async (reviewId: string) => {
        try {
            const url = `${process.env.REACT_APP_POST_IMAGES_API_URL}?reviewID=${reviewId}&brandRefID=${brandId}&apiKey=${brandApiKey}&source=1`;

            const formData = new FormData();
            formData.append('type', 'base64');
            images.forEach((image: ImageInfo) => {
                formData.append('uploadedImages', image.src.split(',')[1]);
            });

            const imagesUploadRes = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `${brandApiKey}`,
                },
                body: formData,
            });

            return imagesUploadRes;
        } catch (error) {
            showError(GROOVY_API_ERROR);
        }
    };

    const handleContactSubmit = async (contactValues: ContactInfoValues) => {
        const detailedReview = formatQuesRes(stepSurveyQuestions);
        const detailAnswers = formatSurveyAnswers(stepSurveyQuestions);

        const mediaType = getMediaType();

        // post the data to groovy API
        const dataToPost = {
            brandRefID: brandDetails._id,
            brandName: brandDetails.name,
            source: '1',
            rating: parseFloat(npsRatings) / 2,
            status: '0',
            npsrating: stepSurveyQuestions[0].answer.selectedAnswer,
            type: mediaType,
            language: 'en',
            review: stepSurveyQuestions[1].answer.answer,
            email: contactValues.email,
            name: contactValues.name,
            phone: contactValues.phone,
            publishstatus: '0',
            detailedReview,
            detailedQuestion: JSON.stringify(detailedQuestions),
            detailedAnswer: detailAnswers,
        };

        // POST this data to groovy api .
        postSurveyDataToGroovyApi(dataToPost, mediaType);
    };

    const handlePrevClick = () => {
        dispatch(updateCurrentStep(totalSteps - 1));
    };

    return (
        <div className="main-content mobile-question-head">
            <div className="col-xl-10">
                <h3>
                    <span className="number contact-step">{totalSteps}</span>
                    {CONTACT_US}
                </h3>
            </div>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    agreeToPolicy: false,
                    isEmailRequired: isEmailRequired,
                    isPhoneRequired: isMobileRequired,
                }}
                validationSchema={validationSchema}
                onSubmit={handleContactSubmit}
            >
                {({ isValid, values, setFieldValue, dirty }) => (
                    <Form className="contact-form">
                        <div className="form-group">
                            <label htmlFor="name">{FULL_NAME} *</label>
                            <Field
                                name="name"
                                type="text"
                                className="form-control input-text input-small"
                            />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">
                                {EMAIL_ADDRESS}
                                {isEmailRequired === 'true' && <>*</>}
                            </label>
                            <Field
                                name="email"
                                type="email"
                                className="form-control input-text input-small"
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">
                                {PHONE_NUMBER}
                                {isMobileRequired === 'true' && <>*</>}
                            </label>
                            <Field name="phone">
                                {({
                                    field,
                                }: {
                                    field: {
                                        value: string;
                                        onChange: (
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => void;
                                    };
                                }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        className="form-control input-text input-small"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        maxLength={10}
                                        onChange={(e) =>
                                            handlePhoneInput(e, setFieldValue)
                                        }
                                    />
                                )}
                            </Field>

                            <ErrorMessage
                                name="phone"
                                component="div"
                                className="error-message"
                            />
                        </div>
                        <div className="form-group">
                            <label className="label-store label-agreement">
                                <Field
                                    type="checkbox"
                                    name="agreeToPolicy"
                                    className="form-control custom-control-input align-middle f"
                                    onChange={() =>
                                        setFieldValue(
                                            'agreeToPolicy',
                                            !values.agreeToPolicy
                                        )
                                    }
                                />
                                <span className="align-middle f"></span>
                                <span className="align-middle ms-1"></span>
                                <span className="agree-policy">
                                    {I_AGREE} &nbsp;
                                    <a
                                        href="https://www.tellofy.com/privacy-policy/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="data-policy"
                                    >
                                        {DATA_POLICY} &nbsp;
                                    </a>
                                    {AND}
                                    <a
                                        href="https://www.tellofy.com/terms-and-conditions/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="data-policy "
                                    >
                                        &nbsp;{TERMS_OF_USE}
                                    </a>
                                </span>
                            </label>
                            <ErrorMessage
                                name="agreeToPolicy"
                                component="div"
                                className="error-message"
                            />
                        </div>
                        <div className="footer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-2 d-none d-lg-block"></div>
                                    <div className="col">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
                                                <div className="row row-8 align-items-center">
                                                    <div className="col-auto d-sm-none">
                                                        <button
                                                            className="btn btn-prev"
                                                            id="backstep-1"
                                                            onClick={() =>
                                                                handlePrevClick()
                                                            }
                                                        >
                                                            <svg
                                                                width="8"
                                                                height="12"
                                                                viewBox="0 0 8 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z"
                                                                    fill="#AF91FF"
                                                                ></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className="col">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-next "
                                                            disabled={
                                                                !isValid ||
                                                                !dirty ||
                                                                loader
                                                            }
                                                        >
                                                            {SUBMIT}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <PoweredByTellofy />
                                            <div className="col-auto d-none d-sm-block">
                                                <div className="pagination-box">
                                                    <span className="align-middle que-num-gap">
                                                        {totalSteps +
                                                            ' of ' +
                                                            totalSteps}
                                                    </span>
                                                    <div className="arrows-box ms-3">
                                                        <button
                                                            onClick={() =>
                                                                handlePrevClick()
                                                            }
                                                            className="arrow arrow-gap"
                                                        >
                                                            <svg
                                                                width="8"
                                                                height="6"
                                                                viewBox="0 0 8 6"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M4 2.19998L0.933333 5.26665L0 4.33331L4 0.333313L8 4.33331L7.06667 5.26665L4 2.19998Z"
                                                                    fill="#6539D9"
                                                                ></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            {loader && (
                <div className="overlay-loader">
                    <div className="outer-loader">
                        <div className="loader">{uploadProgress}%</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactInfo;
