import { RootState } from '@redux/store';

export const getV4SurveyState = (state: RootState) => state;

export const getBrandName = (state: RootState) =>
    state.surveyV4.brandDetails.name;

export const getBrandId = (state: RootState) => state.surveyV4.brandDetails._id;

export const getBrandApiKey = (state: RootState) =>
    state.surveyV4.brandDetails.apiKey;

export const getBrandImage = (state: RootState) =>
    state.surveyV4.brandDetails.imagePaths;

export const getEmailValidation = (state: RootState) =>
    state.surveyV4.brandDetails.emailmandate;

export const getMobileValidation = (state: RootState) =>
    state.surveyV4.brandDetails.mobilemandate;

export const getStepSurveyQuestions = (state: RootState) =>
    state.surveyV4.stepSurveyQuestions;

export const getAllQuestions = (state: RootState) =>
    state.surveyV4.allQuestions;

export const getBrandDetails = (state: RootState) =>
    state.surveyV4.brandDetails;

export const getTotalSteps = (state: RootState) => state.surveyV4.totalSteps;

export const getNpsRatings = (state: RootState) =>
    state.surveyV4.stepSurveyQuestions[0].answer.selectedAnswer;
