export const TEXT = {
    AUDIO_TO_TEXT_IN_PROGRESS: 'Audio to Text in progress',
    SPEECH_NOT_TRANSCRIBED_TO_TEXT:
        'Audio could not be transcribed, please try speaking clearly',
    MAXIMUM_5_IMAGES: 'A maximum of 5 images can be attached',
    DELETE_IMAGE: 'Delete Image',
    DELETE_IMAGE_BODY:
        'The Image will be removed, are you sure you want to delete it?',
    DELETE_VIDEO: 'Delete Video',
    DELETE_VIDEO_BODY:
        'The video will be removed, are you sure you want to delete it?',
    DELETE_AUDIO: 'Delete Audio',
    DELETE_AUDIO_BODY:
        'The audio will be removed, are you sure you want to delete it?',
    DELETE_AUDIO_AND_TEXT: 'Delete Audio and Text',
    DELETE_AUDIO_AND_TEXT_BODY:
        'The audio and converted text will be removed, are you sure you want to delete it?',
    GROOVY_API_ERROR:
        'There was an issue with the submission. Please try again',
    PERMISSIONS_NOT_ALLOWED:
        'Error accessing media devices. Please ensure you have granted the necessary permissions.',
    NO_MEDIA_STREAM_AVAILABLE:
        'No media stream available. Please ensure your camera and microphone are accessible.',
    ERROR_STARTING_MEDIA_RECORDER:
        'Error starting media recorder. Please try again.',
    MICROPHONE_NOT_ALLOWED: 'Microphone not allowed',
};
