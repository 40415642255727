import { FC, useEffect, useState, ChangeEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useRedux';
import { Answer, SurveyStepQuestions } from '@models/survey';
import { getV4SurveyState } from '@redux/v4-survey/selector';
import Images from '@components/nps/multimedia/Images';

import { ImageInfo, SelectedMediaType } from '@models/media';
import GenerateSVG from 'img/generated.svg';
import videojs from 'video.js';

import {
    updateCurrentStep,
    updateCurrentStepAnswer,
} from '@redux/v4-survey/slice';

import { TEXT } from '@constants/messages';
import CustomAudio from '@components/nps/multimedia/CustomAudio';
import { UI_TEXT } from '@constants/ui-text';
import CustomVideo from './video-recorders/CustomVideo';
import { VALUES } from '@constants/values';

const { TEXT_LENGTH } = VALUES;

const { MAXIMUM_5_IMAGES } = TEXT;

const {
    SPEECH_TO_TEXT_GENERATED_BY_AI,
    YOU_HAVE_TOTAL_1_MINUTE_OF_RECORDING,
    SPEECH_TO_TEXT,
    ATTACH_MEDIA,
    IMAGES,
    VIDEO,
    ATTACHED,
    VIDEO_ATTACHED,
    NEXT,
    POWERED_BY,
    TELLOFY,
    TEXT_IS_REQUIRED,
} = UI_TEXT;

interface Question2Props {
    images: ImageInfo[];
    setImages: React.Dispatch<React.SetStateAction<ImageInfo[]>>;
    question: SurveyStepQuestions;
    getCurrentStepFilledAnswer: (
        filledAnswer: Answer,
        question: SurveyStepQuestions
    ) => void;
    audioBlob: Blob | null;
    setAudioBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
    videoBlob: Blob | null;
    setVideoBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
    selectedMediaType: SelectedMediaType;
    setSelectedMediaType: React.Dispatch<
        React.SetStateAction<SelectedMediaType>
    >;
    textByAI: boolean;
    setTextByAI: React.Dispatch<React.SetStateAction<boolean>>;
    convertedTextFromSpeech: boolean;
    setConvertedTextFromSpeech: React.Dispatch<React.SetStateAction<boolean>>;
}

const Question2: FC<Question2Props> = ({
    images,
    setImages,

    question,
    getCurrentStepFilledAnswer,
    audioBlob,
    setAudioBlob,
    videoBlob,
    setVideoBlob,
    selectedMediaType,
    setSelectedMediaType,
    textByAI,
    setTextByAI,
    convertedTextFromSpeech,
    setConvertedTextFromSpeech,
}) => {
    const {
        surveyV4: { stepSurveyQuestions, currentStep, totalSteps },
    } = useAppSelector(getV4SurveyState);
    const [localAnswers, setLocalAnswers] = useState<Answer>(question.answer);
    const [showRecordAudioSection, setShowRecordAudioSection] =
        useState<boolean>(false);
    const [showMediaOptions, setShowMediaOptions] = useState(false);
    const [selectedMediaOption, setSelectedMediaOption] =
        useState<SelectedMediaType>(null);

    const [mediaText, setMediaText] = useState(question.answer.answer);
    const [textLength, setTextLength] = useState<number | null>(
        question.answer.answer.length
    );
    const [errorTextArea, setErrorTextArea] = useState(false);
    const [shoMediaAttachedText, setShowMediaAttachedText] = useState(true);
    const [recordingInProgress, setRecordingInProgress] = useState(false);

    const dispatch = useAppDispatch();

    const setCurrentStepPreFilledValue = () => {
        setLocalAnswers(question.answer);
        return {
            answer: stepSurveyQuestions[currentStep - 1].answer.answer,
        };
    };

    const handleAttachMedia = () => {
        setShowMediaOptions((prev) => !prev);
    };

    const handleSelectMultimediaType = (mediaType: SelectedMediaType) => {
        setShowMediaOptions((prev) => !prev);
        setSelectedMediaOption(mediaType);
    };

    useEffect(() => {
        getCurrentStepFilledAnswer(setCurrentStepPreFilledValue(), question);
    }, [currentStep]);

    const handleClickSpeechToText = () => {
        setShowRecordAudioSection(true);
    };

    const mediaTextChange = (e) => {
        setErrorTextArea(false);
        const text = e.target.value;
        if (text.length === 0) {
            setConvertedTextFromSpeech(false);
            setTextByAI(null);
        }
        setTextLength(text.length);
        setMediaText(text);
        setLocalAnswers({ answer: text });
    };

    const handleNextClick = () => {
        setSelectedMediaOption(null);

        if (!mediaText) {
            setErrorTextArea(true);
            return;
        }

        dispatch(updateCurrentStepAnswer(localAnswers));
        dispatch(updateCurrentStep(currentStep + 1));
    };

    const handlePrevClick = () => {
        dispatch(updateCurrentStep(currentStep - 1));
    };

    const isAudioSectionEnabled = () => {
        return selectedMediaOption === null || selectedMediaOption === 'audio';
    };

    const isNextButtonDisabled = () => {
        if (mediaText === '' && isAudioSectionEnabled()) {
            return true;
        }
    };

    useEffect(() => {
        setLocalAnswers(question.answer);
        setMediaText(question.answer.answer);
        setTextLength(question.answer.answer.length);

        getCurrentStepFilledAnswer(
            {
                answer: stepSurveyQuestions[currentStep - 1].answer.answer,
            },
            question
        );
    }, []);

    return (
        <>
            <div className="main-content mobile-question-head">
                <div className="row">
                    <div className="col-lg-9 col-xl-7">
                        <section>
                            <h3>
                                <span
                                    className={
                                        currentStep.toString().length > 1
                                            ? 'number two-digit'
                                            : 'number'
                                    }
                                >
                                    2
                                </span>
                                {question.text} {question.required && <>*</>}
                                {selectedMediaOption === 'image' && (
                                    <div className="error-message">
                                        {MAXIMUM_5_IMAGES}
                                    </div>
                                )}
                            </h3>

                            {isAudioSectionEnabled() && (
                                <div className="textarea-box">
                                    <div className="position-relative mb-3">
                                        <textarea
                                            className="form-control form-textarea"
                                            placeholder="Please type your comments, or use mic option below to speak about your experience, along with video and pictures to provide your feedback."
                                            id="count-1000"
                                            maxLength={TEXT_LENGTH}
                                            onChange={mediaTextChange}
                                            value={mediaText}
                                            style={{
                                                borderColor:
                                                    errorTextArea &&
                                                    mediaText.length == 0
                                                        ? 'red'
                                                        : '#838bad',
                                            }}
                                        ></textarea>

                                        <div className="speech-controller">
                                            <div
                                                id="the-count-1000"
                                                className="the-count-1000"
                                            >
                                                <span id="current-1000">
                                                    {textLength}
                                                </span>
                                                <span id="maximum-1000">
                                                    /{TEXT_LENGTH}
                                                </span>
                                            </div>
                                            {errorTextArea &&
                                                mediaText.length == 0 && (
                                                    <div className="error-message textarea-validation">
                                                        {TEXT_IS_REQUIRED}
                                                    </div>
                                                )}

                                            <div
                                                className={
                                                    mediaText.length > 0 &&
                                                    textByAI === true
                                                        ? 'd-block generated'
                                                        : 'd-none generated'
                                                }
                                            >
                                                <img src={GenerateSVG} alt="" />
                                                <span className="ms-1">
                                                    {
                                                        SPEECH_TO_TEXT_GENERATED_BY_AI
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="message">
                                        {YOU_HAVE_TOTAL_1_MINUTE_OF_RECORDING}
                                    </div>

                                    {!showRecordAudioSection ? (
                                        <div className="audio-buttons">
                                            <div className="row row-8 align-items-center justify-content-between">
                                                <div className="col-auto mb-2">
                                                    <button
                                                        onClick={
                                                            handleClickSpeechToText
                                                        }
                                                        className="btn btn-next audio-to-text"
                                                    >
                                                        <svg
                                                            className="me-2 align-middle"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M13.5 0L12.7125 1.71875L11 2.5L12.7125 3.2875L13.5 5L14.2812 3.2875L16 2.5L14.2812 1.71875L13.5 0ZM6.58203 8.75065C6.97092 9.13954 7.44314 9.33398 7.9987 9.33398C8.55425 9.33398 9.02648 9.13954 9.41536 8.75065C9.80425 8.36176 9.9987 7.88954 9.9987 7.33398V3.33398C9.9987 2.77843 9.80425 2.30621 9.41536 1.91732C9.02648 1.52843 8.55425 1.33398 7.9987 1.33398C7.44314 1.33398 6.97092 1.52843 6.58203 1.91732C6.19314 2.30621 5.9987 2.77843 5.9987 3.33398V7.33398C5.9987 7.88954 6.19314 8.36176 6.58203 8.75065ZM7.33203 11.9507V14.0007H8.66536V11.9507C9.82092 11.7951 10.7765 11.2784 11.532 10.4007C12.2876 9.52287 12.6654 8.50065 12.6654 7.33398H11.332C11.332 8.25621 11.007 9.04232 10.357 9.69232C9.70703 10.3423 8.92092 10.6673 7.9987 10.6673C7.07648 10.6673 6.29036 10.3423 5.64036 9.69232C4.99036 9.04232 4.66536 8.25621 4.66536 7.33398H3.33203C3.33203 8.50065 3.70981 9.52287 4.46536 10.4007C5.22092 11.2784 6.17648 11.7951 7.33203 11.9507Z"
                                                                fill="#FFF"
                                                            />
                                                        </svg>
                                                        <span className="align-middle">
                                                            {SPEECH_TO_TEXT}
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="col-auto mb-2">
                                                    <div className="dropdown dropdown-media">
                                                        <button
                                                            className="dropdown-toggle btn"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="outside"
                                                            aria-expanded="false"
                                                            onClick={
                                                                handleAttachMedia
                                                            }
                                                        >
                                                            <span className="align-middle">
                                                                {ATTACH_MEDIA}
                                                            </span>
                                                            <svg
                                                                className="align-middle ms-2"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M7.33331 11.3333H8.66665V8.66658H11.3333V7.33325H8.66665V4.66658H7.33331V7.33325H4.66665V8.66658H7.33331V11.3333ZM7.99998 14.6666C7.07776 14.6666 6.21109 14.4916 5.39998 14.1416C4.58887 13.7916 3.88331 13.3166 3.28331 12.7166C2.68331 12.1166 2.20831 11.411 1.85831 10.5999C1.50831 9.78881 1.33331 8.92214 1.33331 7.99992C1.33331 7.0777 1.50831 6.21103 1.85831 5.39992C2.20831 4.58881 2.68331 3.88325 3.28331 3.28325C3.88331 2.68325 4.58887 2.20825 5.39998 1.85825C6.21109 1.50825 7.07776 1.33325 7.99998 1.33325C8.9222 1.33325 9.78887 1.50825 10.6 1.85825C11.4111 2.20825 12.1166 2.68325 12.7166 3.28325C13.3166 3.88325 13.7916 4.58881 14.1416 5.39992C14.4916 6.21103 14.6666 7.0777 14.6666 7.99992C14.6666 8.92214 14.4916 9.78881 14.1416 10.5999C13.7916 11.411 13.3166 12.1166 12.7166 12.7166C12.1166 13.3166 11.4111 13.7916 10.6 14.1416C9.78887 14.4916 8.9222 14.6666 7.99998 14.6666Z"
                                                                    fill="#6539D9"
                                                                />
                                                            </svg>
                                                        </button>
                                                        {showMediaOptions && (
                                                            <div className="dropdown-menu show">
                                                                <button
                                                                    className="dropdown-item image-to"
                                                                    onClick={() =>
                                                                        handleSelectMultimediaType(
                                                                            'image'
                                                                        )
                                                                    }
                                                                >
                                                                    {IMAGES}
                                                                </button>
                                                                <button
                                                                    className="dropdown-item video-to"
                                                                    onClick={() =>
                                                                        handleSelectMultimediaType(
                                                                            'video'
                                                                        )
                                                                    }
                                                                >
                                                                    {VIDEO}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <CustomAudio
                                            audioBlob={audioBlob}
                                            setAudioBlob={setAudioBlob}
                                            setMediaText={setMediaText}
                                            setTextLength={setTextLength}
                                            setShowRecordAudioSection={
                                                setShowRecordAudioSection
                                            }
                                            setSelectedMediaType={
                                                setSelectedMediaType
                                            }
                                            setLocalAnswers={setLocalAnswers}
                                            setTextByAI={setTextByAI}
                                            textByAI={textByAI}
                                            convertedTextFromSpeech={
                                                convertedTextFromSpeech
                                            }
                                            setConvertedTextFromSpeech={
                                                setConvertedTextFromSpeech
                                            }
                                            setRecordingInProgress={
                                                setRecordingInProgress
                                            }
                                        />
                                    )}
                                </div>
                            )}
                            {selectedMediaOption === 'image' && (
                                <Images
                                    images={images}
                                    setImages={setImages}
                                    setSelectedMediaType={setSelectedMediaType}
                                    setShowMediaAttachedText={
                                        setShowMediaAttachedText
                                    }
                                />
                            )}

                            {selectedMediaOption === 'video' && (
                                <CustomVideo
                                    videoBlob={videoBlob}
                                    setVideoBlob={setVideoBlob}
                                    setSelectedMediaType={setSelectedMediaType}
                                    setSelectedMediaOption={
                                        setSelectedMediaOption
                                    }
                                    setShowMediaAttachedText={
                                        setShowMediaAttachedText
                                    }
                                />
                            )}
                        </section>
                    </div>
                </div>

                {!showRecordAudioSection &&
                    shoMediaAttachedText &&
                    selectedMediaType === 'image' &&
                    !showMediaOptions && (
                        <div className="row media-attached">
                            {images.length}{' '}
                            {images.length > 1 ? 'Images' : 'Image'} {ATTACHED}
                        </div>
                    )}

                {!showRecordAudioSection &&
                    shoMediaAttachedText &&
                    selectedMediaType === 'video' &&
                    !showMediaOptions && (
                        <div className="row media-attached">
                            {VIDEO_ATTACHED}
                        </div>
                    )}
            </div>

            {/* Footer component for Question 2 */}

            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-2 d-none d-lg-block"></div>
                        <div className="col">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-12 col-sm-auto mb-2 mb-sm-0">
                                    <div className="row row-8 align-items-center">
                                        {currentStep > 1 && (
                                            <div className="col-auto d-sm-none">
                                                <button
                                                    disabled={
                                                        recordingInProgress
                                                    }
                                                    onClick={() =>
                                                        handlePrevClick()
                                                    }
                                                    className="btn btn-prev"
                                                    id="backstep-1"
                                                >
                                                    <svg
                                                        width="8"
                                                        height="12"
                                                        viewBox="0 0 8 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z"
                                                            fill="#AF91FF"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        )}

                                        <div className="col">
                                            <button
                                                className="btn btn-next"
                                                style={{
                                                    border: '1px solid #af91ff',
                                                }}
                                                id="tostep-3"
                                                disabled={
                                                    isNextButtonDisabled() ||
                                                    recordingInProgress
                                                }
                                                onClick={handleNextClick}
                                            >
                                                {NEXT}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-auto">
                                    <span className="copywright">
                                        {POWERED_BY}{' '}
                                        <a
                                            href="https://www.tellofy.com/"
                                            target="_blank"
                                            className="link-brand"
                                            rel="noreferrer"
                                        >
                                            {TELLOFY}
                                        </a>
                                    </span>
                                </div>
                                <div className="col-auto d-none d-sm-block">
                                    <div className="pagination-box">
                                        <span className="align-middle que-num-gap">
                                            {currentStep + ' of ' + totalSteps}
                                        </span>
                                        <div className="arrows-box ms-3">
                                            {currentStep > 1 && (
                                                <button
                                                    disabled={
                                                        recordingInProgress
                                                    }
                                                    onClick={() =>
                                                        handlePrevClick()
                                                    }
                                                    className="arrow arrow-gap"
                                                >
                                                    <svg
                                                        width="8"
                                                        height="6"
                                                        viewBox="0 0 8 6"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M4 2.19998L0.933333 5.26665L0 4.33331L4 0.333313L8 4.33331L7.06667 5.26665L4 2.19998Z"
                                                            fill="#6539D9"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            )}

                                            {currentStep < totalSteps && (
                                                <button
                                                    disabled={
                                                        isNextButtonDisabled() ||
                                                        recordingInProgress
                                                    }
                                                    onClick={() =>
                                                        handleNextClick()
                                                    }
                                                    className="arrow"
                                                    id="tostep-3b"
                                                >
                                                    <svg
                                                        width="8"
                                                        height="6"
                                                        viewBox="0 0 8 6"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M4 5.26665L0 1.26665L0.933333 0.333313L4 3.39998L7.06667 0.333313L8 1.26665L4 5.26665Z"
                                                            fill="#6539D9"
                                                        />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Question2;
