import {
    FC,
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useState,
    useEffect,
} from 'react';
import uploadPhoto from 'img/upload-photo.svg';
import ConfirmationModal from '@components/UI/ConfirmationModal';
import useDialog from '@hooks/useDialog';
import { ImageInfo, SelectedMediaType } from '@models/media';
import { createPortal } from 'react-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { TEXT } from '@constants/messages';

const { DELETE_IMAGE, DELETE_IMAGE_BODY } = TEXT;

interface ImagesProps {
    images: ImageInfo[];
    setImages: Dispatch<SetStateAction<ImageInfo[]>>;
    setSelectedMediaType: React.Dispatch<
        React.SetStateAction<SelectedMediaType>
    >;
    setShowMediaAttachedText: React.Dispatch<React.SetStateAction<boolean>>;
}

const Images: FC<ImagesProps> = ({
    images,
    setImages,
    setSelectedMediaType,
    setShowMediaAttachedText,
}) => {
    const { showDialog, dialogData, hideDialog } = useDialog(false);

    const [showPopup, setShowPopup] = useState(false);

    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [indexToDelete, setIndexToDelete] = useState<null | number>(null);

    const handleImageFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            const maxLength = parseInt(
                event.target.dataset.max_length || '10',
                10
            );

            files.forEach((file) => {
                if (
                    !file.type.startsWith('image/') ||
                    images.length >= maxLength
                ) {
                    return;
                }
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e.target?.result) {
                        const newImage: ImageInfo = {
                            src: e.target.result.toString(),
                            title: file.name,
                        };

                        setImages((prevImages) => {
                            const updatedImages = [...prevImages, newImage];
                            if (updatedImages.length > 5) {
                                return updatedImages.slice(0, 5);
                            }
                            return updatedImages;
                        });
                    }
                };
                reader.readAsDataURL(file);
            });
        }
    };

    const handleDeleteImage = (
        indexToRemove: number,
        event: React.MouseEvent
    ) => {
        setIndexToDelete(indexToRemove);
        setShowPopup(true);
        showDialog<{ heading: string; body: string }>({
            heading: DELETE_IMAGE,
            body: DELETE_IMAGE_BODY,
        });
    };

    const deleteImg = () => {
        setImages((prevImages) =>
            prevImages.filter((_, index) => index !== indexToDelete)
        );
        setShowPopup(false);
    };

    const onCancel = () => {
        setIndexToDelete(null);
        setShowPopup(false);
        hideDialog();
    };

    const handlePreviewImage = (imageIndexToPreview: number) => {
        setCurrentIndex(imageIndexToPreview);
        setShowGallery(true);
    };

    // use showPopup to show the dialog
    const renderConfirmDialog = () => {
        return showPopup
            ? createPortal(
                  <ConfirmationModal
                      onSuccess={deleteImg}
                      onCancel={onCancel}
                      dialogData={dialogData}
                  />,
                  document.body
              )
            : null;
    };

    const createImageGallery = () => {
        return images.map((image) => {
            return { original: image.src, thumbnail: image.src };
        });
    };

    const handleCloseClick = () => {
        setShowGallery(false);
    };

    const renderCustomControls = () => (
        <div className="custom-controls">
            <button className="close-button" onClick={handleCloseClick}>
                &times;
            </button>

            <div className="image-count">
                {currentIndex + 1} / {images.length}
            </div>
        </div>
    );

    const updateMediaType = () => {
        if (images.length > 0) {
            setSelectedMediaType('image');
        } else {
            setSelectedMediaType(null);
        }
    };

    useEffect(() => {
        updateMediaType();
    }, [images]);

    useEffect(() => {
        setShowMediaAttachedText(false);

        return () => {
            setShowMediaAttachedText(true);
        };
    }, []);

    return (
        <div>
            {showGallery &&
                createPortal(
                    <ImageGallery
                        items={createImageGallery()}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={false}
                        autoPlay={false}
                        renderCustomControls={renderCustomControls}
                    />,
                    document.body
                )}
            <div className="images-uploading">
                <div className="upload__box">
                    <div className="row row-10 upload__img-wrap">
                        {images.length < 5 && (
                            <div className="col-6 col-sm-4 order-last">
                                <div className="upload__btn-box">
                                    <div className="upload__btn">
                                        <div className="upload-img">
                                            <img src={uploadPhoto} alt="" />
                                        </div>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleImageFileChange}
                                            data-max_length="20"
                                            disabled={images.length >= 5}
                                            className="upload__inputfile"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {images.length > 0 &&
                            images.map((image, index) => (
                                <div
                                    key={image.toString() as string}
                                    className="upload__img-box"
                                >
                                    <a className="magnific-popup">
                                        <div className="img-bg">
                                            <img
                                                src={image.src}
                                                alt={image.title}
                                            />
                                        </div>
                                    </a>
                                    <div
                                        className="upload__img-delete"
                                        onClick={(event) =>
                                            handleDeleteImage(index, event)
                                        }
                                    >
                                        <svg
                                            width="13"
                                            height="15"
                                            viewBox="0 0 16 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3.00018 18C2.45018 18 1.97935 17.8042 1.58768 17.4125C1.19602 17.0208 1.00018 16.55 1.00018 16V3H0.000183105V1H5.00018V0H11.0002V1H16.0002V3H15.0002V16C15.0002 16.55 14.8043 17.0208 14.4127 17.4125C14.021 17.8042 13.5502 18 13.0002 18H3.00018ZM5.00018 14H7.00018V5H5.00018V14ZM9.00018 14H11.0002V5H9.00018V14Z"
                                                fill="#AF91FF"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div
                                        className="upload__img-play"
                                        onClick={() =>
                                            handlePreviewImage(index)
                                        }
                                    >
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.11646 12.7498L12.9498 8.99984L7.11646 5.24984V12.7498ZM9.19979 17.3332C8.04701 17.3332 6.96368 17.1144 5.94979 16.6769C4.9359 16.2394 4.05396 15.6457 3.30396 14.8957C2.55395 14.1457 1.96021 13.2637 1.52271 12.2498C1.08521 11.2359 0.866455 10.1526 0.866455 8.99984C0.866455 7.84706 1.08521 6.76373 1.52271 5.74984C1.96021 4.73595 2.55395 3.854 3.30396 3.104C4.05396 2.354 4.9359 1.76025 5.94979 1.32275C6.96368 0.885254 8.04701 0.666504 9.19979 0.666504C10.3526 0.666504 11.4359 0.885254 12.4498 1.32275C13.4637 1.76025 14.3456 2.354 15.0956 3.104C15.8456 3.854 16.4394 4.73595 16.8769 5.74984C17.3144 6.76373 17.5331 7.84706 17.5331 8.99984C17.5331 10.1526 17.3144 11.2359 16.8769 12.2498C16.4394 13.2637 15.8456 14.1457 15.0956 14.8957C14.3456 15.6457 13.4637 16.2394 12.4498 16.6769C11.4359 17.1144 10.3526 17.3332 9.19979 17.3332Z"
                                                fill="#AF91FF"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div className="upload__img-bottom"></div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

            {showPopup && renderConfirmDialog()}
        </div>
    );
};

export default Images;
