export const UI_TEXT = {
    SURVEY_ON_TELLOFY: 'Survey on Tellofy',
    SURVEY_APP_TITLE: 'Feedback',
    PAGE_NOT_FOUND: 'Page Not Found',
    SPEECH_TO_TEXT_GENERATED_BY_AI: 'AI Generated',
    YOU_HAVE_TOTAL_1_MINUTE_OF_RECORDING:
        'You have a total of 60 seconds of recording',
    SPEECH_TO_TEXT: 'Speech to Text',
    ATTACH_MEDIA: 'Attach Media',
    IMAGES: 'Images',
    VIDEO: 'Video',
    ATTACHED: 'attached',
    VIDEO_ATTACHED: 'Video attached',
    NEXT: 'Next',
    POWERED_BY: 'powered by',
    TELLOFY: 'tellofy',
    YES_DELETE: 'Yes, Delete',
    NO_CANCEL: 'No, Cancel',
    BACK_TO_TELLOFY: 'Back to tellofy.com',
    CONTACT_US: 'Contact Us',
    FULL_NAME: 'Full Name',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number',
    I_AGREE: 'I agree to the',
    DATA_POLICY: 'Data Policy',
    AND: 'and',
    TERMS_OF_USE: 'Terms of Use.',
    WANT_TO_SAVE_AUDIO:
        ' Would you like to save your audio recording and have it published with your reviews?',
    SUBMIT: 'Submit',
    YOUR_FEEDBACK_IS_VERY_VALUABLE: 'Your feedback is very valuable for us!',
    TEXT_IS_REQUIRED: 'Text is required',
};
