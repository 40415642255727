import { createAsyncThunk } from '@reduxjs/toolkit';

import { BRAND } from 'constants/api-endpoints';
import {
    getBrandDetails,
    getBrandQuestionnaires,
    uploadTemporaryAudio,
    uploadTemporaryImages,
    uploadTemporaryVideo,
} from './api';

const { DETAILS } = BRAND;

export const getBrandDetailsAsync = createAsyncThunk(
    'surveyV4/brand-details',
    async (feedbackUrl: string, { rejectWithValue }) => {
        try {
            const response = await getBrandDetails(feedbackUrl);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBrandQuestionnaireAsync = createAsyncThunk(
    'surveyV4/brand-questionnaire',
    async (questionId: string, { rejectWithValue }) => {
        try {
            const response = await getBrandQuestionnaires(questionId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadImagesToS3 = createAsyncThunk(
    'surveyV4/upload-images',
    async (images: File[], { rejectWithValue }) => {
        try {
            const formData = new FormData();
            images.forEach((image, index) => {
                formData.append(`files[${index}]`, image);
            });
            const response = await uploadTemporaryImages(formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadAudioToS3 = createAsyncThunk(
    'surveyV4/upload-audio',
    async (audioFile: File, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', audioFile);
            const response = await uploadTemporaryAudio(formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadVideoToS3 = createAsyncThunk(
    'surveyV4/upload-video',
    async (videoFiles: File[], { rejectWithValue }) => {
        try {
            const formData = new FormData();
            videoFiles.forEach((videoFile, index) => {
                formData.append(`files[${index}]`, videoFile);
            });

            const response = await uploadTemporaryVideo(formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
