import { FC } from 'react';

import Logo from '../img/Logo.svg';
type HeaderType = {
    brandName: string;
    brandIcon: string;
};

const Header: FC<HeaderType> = ({ brandName, brandIcon }) => {
    return (
        <div className="header">
            <div className="container">
                <div className="row align-items-center">
                    {/* hiding the tellofy logo from the header */}
                    {/* <div className="col col-lg-2">
                        <a
                            href="https://www.tellofy.com/"
                            target="_blank"
                            className="logo"
                            rel="noreferrer"
                        >
                            <img src={Logo} alt="Tellofy Logo" />
                        </a>
                    </div> */}
                    <div className="col-auto col-lg">
                        <div className="brand row row-6 align-items-center">
                            {brandIcon && (
                                <div className="col-auto order-2 order-lg-1">
                                    <img src={brandIcon} alt="Brand Icon" />
                                </div>
                            )}

                            <div className="col-auto order-1 order-lg-2">
                                {brandName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
