import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useRedux';
import { Answer, StepAnswer, SurveyStepQuestions } from '@models/survey';
import { getV4SurveyState } from '@redux/v4-survey/selector';
import {
    updateCurrentStep,
    updateCurrentStepAnswer,
} from '@redux/v4-survey/slice';
import PoweredByTellofy from './UI/PoweredByTellofy';
import { UI_TEXT } from '@constants/ui-text';

const { NEXT } = UI_TEXT;

type FooterProps = {
    question: SurveyStepQuestions;
    isCurrentStepRequired: boolean;
    currentStepAnswer: Answer;
    handleNext: (currentStepData: StepAnswer) => void;
    handlePrevClick: () => void;
};

const Footer: FC<FooterProps> = ({
    question,
    currentStepAnswer,
    handlePrevClick,
}) => {
    const {
        surveyV4: { currentStep, totalSteps },
    } = useAppSelector(getV4SurveyState);
    const dispatch = useAppDispatch();

    const handleNextButton = () => {
        if (currentStep === 2) {
            dispatch(updateCurrentStep(currentStep + 1));
            return;
        }
        dispatch(updateCurrentStepAnswer(currentStepAnswer));
        dispatch(updateCurrentStep(currentStep + 1));
    };

    // const isCurrentFieldRequiredAndNotFilled = () => {
    //     if (question && question.required) {
    //         switch (question.type) {
    //             case 'text':
    //             case 'time':
    //             case 'date':
    //                 let answer = currentStepAnswer?.answer;
    //                 if (!answer || answer.trim() === '') {
    //                     return true;
    //                 }
    //                 break;
    //             case 'radio':
    //             case 'scale':
    //             case 'nps':
    //             case 'select':
    //                 let selectedAnswer = currentStepAnswer?.selectedAnswer;
    //                 if (
    //                     !selectedAnswer ||
    //                     (typeof selectedAnswer === 'string' &&
    //                         selectedAnswer.trim() === '')
    //                 ) {
    //                     return true;
    //                 }
    //                 break;
    //             case 'checkbox':
    //                 let selectedAnswers = currentStepAnswer?.selectedAnswers;

    //                 if (
    //                     !selectedAnswers ||
    //                     !Array.isArray(selectedAnswers) ||
    //                     selectedAnswers.length === 0
    //                 ) {
    //                     return true;
    //                 }
    //                 break;

    //             default:
    //                 return false;
    //         }
    //     }
    //     return false;
    // };

    const isCurrentFieldRequiredAndNotFilled = () => {
        if (!question || !question.required) {
            return false;
        }

        return isFieldEmpty(question.type, currentStepAnswer);
    };

    const isFieldEmpty = (type: string, currentStepAnswer: Answer) => {
        switch (type) {
            case 'text':
            case 'time':
            case 'date':
                let answer = currentStepAnswer?.answer;
                if (!answer || answer.trim() === '') {
                    return true;
                }
                break;
            case 'radio':
            case 'scale':
            case 'nps':
            case 'select':
                let selectedAnswer = currentStepAnswer?.selectedAnswer;
                if (
                    !selectedAnswer ||
                    (typeof selectedAnswer === 'string' &&
                        selectedAnswer.trim() === '')
                ) {
                    return true;
                }
                break;
            case 'checkbox':
                let selectedAnswers = currentStepAnswer?.selectedAnswers;

                if (
                    !selectedAnswers ||
                    !Array.isArray(selectedAnswers) ||
                    selectedAnswers.length === 0
                ) {
                    return true;
                }
                break;

            default:
                return false;
        }
    };

    return (
        <div
            className="footer"
            style={{ zIndex: currentStep === totalSteps ? '' : 9999 }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-2 d-none d-lg-block"></div>
                    <div className="col">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
                                <div className="row row-8 align-items-center">
                                    {currentStep > 1 && (
                                        <div className="col-auto d-sm-none">
                                            <button
                                                onClick={() =>
                                                    handlePrevClick()
                                                }
                                                className="btn btn-prev"
                                                id="backstep-1"
                                            >
                                                <svg
                                                    width="8"
                                                    height="12"
                                                    viewBox="0 0 8 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z"
                                                        fill="#AF91FF"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    )}
                                    {currentStep < totalSteps && (
                                        <div className="col">
                                            <button
                                                className="btn btn-next"
                                                id="tostep-2"
                                                disabled={isCurrentFieldRequiredAndNotFilled()}
                                                onClick={handleNextButton}
                                            >
                                                {NEXT}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <PoweredByTellofy />
                            <div className="col-auto d-none d-sm-block">
                                <div className="pagination-box">
                                    <span className="align-middle que-num-gap">
                                        {currentStep + ' of ' + totalSteps}
                                    </span>
                                    <div className="arrows-box ms-3">
                                        {currentStep > 1 && (
                                            <button
                                                onClick={() =>
                                                    handlePrevClick()
                                                }
                                                className="arrow arrow-gap"
                                            >
                                                <svg
                                                    width="8"
                                                    height="6"
                                                    viewBox="0 0 8 6"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M4 2.19998L0.933333 5.26665L0 4.33331L4 0.333313L8 4.33331L7.06667 5.26665L4 2.19998Z"
                                                        fill="#6539D9"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )}

                                        {currentStep < totalSteps && (
                                            <button
                                                disabled={isCurrentFieldRequiredAndNotFilled()}
                                                onClick={() =>
                                                    handleNextButton()
                                                }
                                                className="arrow"
                                                id="tostep-3b"
                                            >
                                                <svg
                                                    width="8"
                                                    height="6"
                                                    viewBox="0 0 8 6"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M4 5.26665L0 1.26665L0.933333 0.333313L4 3.39998L7.06667 0.333313L8 1.26665L4 5.26665Z"
                                                        fill="#6539D9"
                                                    />
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
