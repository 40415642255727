import { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Answer, StepAnswer, SurveyStepQuestions } from '@models/survey';
import CustomQuestion from './CustomQuestion';

type QuestionStepProps = {
    question: SurveyStepQuestions;
    getCurrentStepFilledAnswer: (
        filledAnswer: Answer,
        question: SurveyStepQuestions
    ) => void;
};

const QuestionSteps: FC<QuestionStepProps> = ({
    question,
    getCurrentStepFilledAnswer,
}) => {
    const getValidationSchema = (question: SurveyStepQuestions) => {
        switch (question.type) {
            case 'text':
                return Yup.string();

            case 'radio':
                return Yup.string();

            case 'checkbox':
                return Yup.array();

            case 'select':
                return Yup.string();

            case 'date':
                return Yup.date();

            case 'time':
                return Yup.string();

            case 'scale':
                return Yup.string();

            default:
                return Yup.string();
        }
    };

    const validationSchema = Yup.object().shape({
        [question.id]: getValidationSchema(question),
    });

    const handleSubmit = (values: any) => {
        const answer: StepAnswer = {
            questionid: question.id,
            image: [],
        };

        switch (question.type) {
            case 'checkbox':
                answer.selectedAnswers = values[question.id];
                break;
            case 'radio':
            case 'select':
            case 'scale':
            case 'nps':
                answer.selectedAnswer = values[question.id];
                break;
            case 'text':
            case 'npstext':
            case 'date':
            case 'time':
                answer.answer = values[question.id];
                break;
            default:
                answer.answer = values[question.id];
        }
        if (question.department) {
            answer.departmentRefID = question.department;
        }
    };

    return (
        <Formik
            initialValues={question.answer}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ errors, touched }) => (
                <Form className="main-form-new">
                    <div className="main-content">
                        <div className="row">
                            <div className="col-xl-10">
                                <CustomQuestion
                                    question={question}
                                    getCurrentStepFilledAnswer={
                                        getCurrentStepFilledAnswer
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default QuestionSteps;
