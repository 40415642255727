import Survey from '@pages/Survey';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/global.css';
import NotFound from '@pages/NotFound';
import Thankyou from '@components/Thankyou';

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/feedback/V4/:feedbackUrl"
                        element={<Survey />}
                    />
                    <Route path="/thankyou" element={<Thankyou />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
