import { configureStore } from '@reduxjs/toolkit';
import surveyV4Reducer from './v4-survey/slice';

export const store = configureStore({
    reducer: {
        surveyV4: surveyV4Reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
