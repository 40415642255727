import { UI_TEXT } from '@constants/ui-text';

const { POWERED_BY, TELLOFY, BACK_TO_TELLOFY } = UI_TEXT;

const ThankyouFooter = () => {
    return (
        <div className="footer" style={{ zIndex: 9999 }}>
            <div className="container">
                <div className="row">
                    <div className="col-2 d-none d-lg-block"></div>
                    <div className="col">
                        <div className="row align-items-center justify-content-between">
                            <div className="col">
                                <span className="copywright">
                                    {POWERED_BY} &nbsp;
                                    <a
                                        href="https://www.tellofy.com/"
                                        target="_blank"
                                        className="link-brand"
                                        rel="noreferrer"
                                    >
                                        {TELLOFY}
                                    </a>
                                </span>
                            </div>
                            <div className="col-auto d-none d-sm-block">
                                <div className="pagination-box">
                                    <span className="align-middle">
                                        {BACK_TO_TELLOFY}
                                    </span>
                                    <div className="arrows-box ms-3">
                                        <a
                                            href="https://www.tellofy.com/"
                                            target="_blank"
                                            className="arrow"
                                            rel="noreferrer"
                                        >
                                            <svg
                                                width="12"
                                                height="8"
                                                viewBox="0 0 12 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.77996 4.66667L6.39329 7.06L7.33329 8L11.3333 4L7.33329 -2.91409e-07L6.39329 0.939999L8.77996 3.33333L0.666626 3.33333L0.666626 4.66667L8.77996 4.66667Z"
                                                    fill="#6539D9"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankyouFooter;
