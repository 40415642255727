import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/useRedux';
import { getV4SurveyState } from '@redux/v4-survey/selector';
import RatingSection from '@components/common/RatingSection';
import { Answer, SurveyStepQuestions } from '@models/survey';

interface Question1Props {
    question: SurveyStepQuestions;
    getCurrentStepFilledAnswer: (
        filledAnswer: Answer,
        question: SurveyStepQuestions
    ) => void;
}

const Question1: FC<Question1Props> = ({
    question,
    getCurrentStepFilledAnswer,
}) => {
    const {
        surveyV4: { currentStep, stepSurveyQuestions },
    } = useAppSelector(getV4SurveyState);

    const [selectedValue, setSelectedValue] = useState<string>(
        stepSurveyQuestions[currentStep - 1].answer.selectedAnswer
    );

    const { text, mintext, maxtext, minscale, maxscale, required } = question;

    const handleRadioChange = (value: string) => {
        setSelectedValue(value);
        getCurrentStepFilledAnswer({ selectedAnswer: value }, question);
    };
    useEffect(() => {
        getCurrentStepFilledAnswer(
            {
                selectedAnswer:
                    stepSurveyQuestions[currentStep - 1].answer.selectedAnswer,
            },
            question
        );
    }, []);
    return (
        <form className="form-steps step-1" id="form1" noValidate>
            <div className="main-content">
                <div className="row">
                    <div className="col-xl-10">
                        <RatingSection
                            currentStep={currentStep}
                            selectedValue={selectedValue}
                            text={text}
                            required={required}
                            mintext={mintext}
                            maxtext={maxtext}
                            minscale={minscale}
                            maxscale={maxscale}
                            onRadioChange={handleRadioChange}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Question1;
