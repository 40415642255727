import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '@hooks/useRedux';
import { getBrandQuestionnaires } from '@redux/v4-survey/api';
import { getV4SurveyState } from '@redux/v4-survey/selector';
import { getBrandDetailsAsync } from '@redux/v4-survey/thunk';
import { formatSurveyQuestions } from 'utils/format-survey-questions';
import NotFound from './NotFound';
import {
    initializeFormData,
    saveCurrentStepAnswer,
    updateAllQuestions,
    updateCurrentStep,
    updateStepSurveyQuestions,
    updateTotalSteps,
} from '@redux/v4-survey/slice';
import Header from '@components/Header';
import QuestionSteps from '@components/QuestionSteps';
import Footer from '@components/Footer';
import ProgressBar from '@components/common/ProgressBar';
import ContactInfo from '@components/ContactInfo';
import { ImageInfo, SelectedMediaType } from '@models/media';
import { Answer, StepAnswer, SurveyStepQuestions } from '@models/survey';
import Question1 from '@components/nps/Question1';
import Question2 from '@components/nps/Question2';
import { UI_TEXT } from '@constants/ui-text';
import { PROD_IMAGE_PATH, REDIRECT_ULS } from '@constants/api-endpoints';

const { FEEDBACK_URL } = REDIRECT_ULS;
const { MAIN, SIZE } = PROD_IMAGE_PATH;
const { SURVEY_APP_TITLE } = UI_TEXT;

const Survey: FC = () => {
    const [images, setImages] = useState<ImageInfo[]>([]);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
    const [convertedTextFromSpeech, setConvertedTextFromSpeech] = useState<
        boolean | null
    >(null);
    const [selectedMediaType, setSelectedMediaType] =
        useState<SelectedMediaType>(null);
    const [textByAI, setTextByAI] = useState<boolean | null>(null);
    const { feedbackUrl } = useParams();
    const [globalError, setGlobalError] = useState(false);
    const [currentStepAnswer, setCurrentStepAnswer] = useState<Answer | null>(
        null
    );

    const dispatch = useAppDispatch();

    const {
        surveyV4: {
            brandDetails,
            currentStep,
            isCurrentStepRequired,
            stepSurveyQuestions,
            totalSteps,
        },
    } = useAppSelector(getV4SurveyState);

    console.log('selectedMediaType >> ', selectedMediaType);

    const getBrandAndQuestionnaire = async () => {
        try {
            const { data } = await dispatch(
                getBrandDetailsAsync(feedbackUrl)
            ).unwrap();

            const { data: brandQuestionnaire } = await getBrandQuestionnaires(
                data.question.refId
            );

            dispatch(updateAllQuestions(brandQuestionnaire.data.question));

            const { formattedQuestions, initialValues } = formatSurveyQuestions(
                brandQuestionnaire.data.question
            );

            dispatch(updateStepSurveyQuestions(formattedQuestions));
            dispatch(initializeFormData(initialValues));

            dispatch(updateTotalSteps(formattedQuestions.length + 1));
            setGlobalError(false);
        } catch (error) {
            setGlobalError(true);
        }
    };

    const question = stepSurveyQuestions[currentStep - 1];

    const getCurrentStepFilledAnswer = (
        filledAnswer: Answer,
        question: SurveyStepQuestions
    ) => {
        setCurrentStepAnswer(filledAnswer);
    };

    const handleNext = (data: StepAnswer) => {
        if (currentStep === totalSteps) {
            return;
        }
        dispatch(saveCurrentStepAnswer({ step: currentStep, answer: data }));
        dispatch(updateCurrentStep(currentStep + 1));
    };

    const handlePrevClick = () => {
        if (currentStep < 1) {
            return;
        }

        dispatch(updateCurrentStep(currentStep - 1));
    };

    const brandLogo = () => {
        const brandIconPath: any = brandDetails?.imagePaths[0];
        if (brandIconPath) {
            if (brandIconPath.startsWith('brand')) {
                return `${MAIN}${brandIconPath}${SIZE}`;
            } else {
                return brandIconPath;
            }
        } else {
            return '';
        }
    };
    useEffect(() => {
        localStorage.setItem(FEEDBACK_URL, feedbackUrl);
        getBrandAndQuestionnaire();
    }, [feedbackUrl]);
    return (
        <>
            {brandDetails?.name && (
                <Helmet>
                    <title>
                        {brandDetails.name}&nbsp;{SURVEY_APP_TITLE}
                    </title>
                </Helmet>
            )}

            {globalError ? (
                <NotFound />
            ) : (
                <>
                    {brandDetails && totalSteps && (
                        <>
                            <ProgressBar
                                currentStep={currentStep}
                                totalSteps={totalSteps}
                            />
                            <Header
                                brandName={brandDetails.name}
                                brandIcon={brandLogo()}
                            />

                            <div className="question-header">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-2 d-none d-lg-block">
                                            &nbsp;
                                        </div>
                                        <div className="col steps">
                                            {currentStep < totalSteps && (
                                                <>
                                                    <h1 className="text-capital">
                                                        {question.name}
                                                    </h1>
                                                    <div className="h1-comment">
                                                        {question.pageSubtitle}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Main Section Where Step Questions Render */}
                            <main className="main-section container">
                                <div className="row flex-grow-1">
                                    <div className="col-2 d-none d-lg-block"></div>
                                    <div className="col steps">
                                        {currentStep === 1 && (
                                            <Question1
                                                question={question}
                                                getCurrentStepFilledAnswer={
                                                    getCurrentStepFilledAnswer
                                                }
                                            />
                                        )}
                                        {currentStep === 2 && (
                                            <Question2
                                                images={images}
                                                setImages={setImages}
                                                question={question}
                                                getCurrentStepFilledAnswer={
                                                    getCurrentStepFilledAnswer
                                                }
                                                audioBlob={audioBlob}
                                                setAudioBlob={setAudioBlob}
                                                videoBlob={videoBlob}
                                                setVideoBlob={setVideoBlob}
                                                selectedMediaType={
                                                    selectedMediaType
                                                }
                                                setSelectedMediaType={
                                                    setSelectedMediaType
                                                }
                                                textByAI={textByAI}
                                                setTextByAI={setTextByAI}
                                                convertedTextFromSpeech={
                                                    convertedTextFromSpeech
                                                }
                                                setConvertedTextFromSpeech={
                                                    setConvertedTextFromSpeech
                                                }
                                            />
                                        )}
                                        {currentStep > 2 &&
                                            currentStep < totalSteps && (
                                                <QuestionSteps
                                                    question={question}
                                                    getCurrentStepFilledAnswer={
                                                        getCurrentStepFilledAnswer
                                                    }
                                                />
                                            )}
                                        {currentStep === totalSteps && (
                                            <ContactInfo
                                                images={images}
                                                audioBlob={audioBlob}
                                                videoBlob={videoBlob}
                                                selectedMediaType={
                                                    selectedMediaType
                                                }
                                                convertedTextFromSpeech={
                                                    convertedTextFromSpeech
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </main>
                            {/* Main Section End */}

                            {/* Footer Section */}
                            {currentStep !== 2 && currentStep != totalSteps && (
                                <Footer
                                    question={question}
                                    currentStepAnswer={currentStepAnswer}
                                    isCurrentStepRequired={
                                        isCurrentStepRequired
                                    }
                                    handleNext={handleNext}
                                    handlePrevClick={handlePrevClick}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Survey;
