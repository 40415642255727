import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getBrandDetailsAsync } from './thunk';
import v4InitialState from './initial-state';
import { Answer, SurveyAnswer, SurveyStepQuestions } from '@models/survey';
import { QuestionPages } from '@models/brand-questionaire';

export const surveyV4Slice = createSlice({
    name: 'surveyV4',
    initialState: v4InitialState,
    reducers: {
        updateAllQuestions: (state, action: PayloadAction<QuestionPages>) => {
            state.allQuestions = action.payload;
        },

        updateStepSurveyQuestions: (
            state,
            action: PayloadAction<SurveyStepQuestions[]>
        ) => {
            state.stepSurveyQuestions = action.payload;
        },

        initializeFormData: (
            state,
            action: PayloadAction<{ [key: string]: any }[]>
        ) => {
            state.formData = action.payload;
        },

        updateTotalSteps: (state, action: PayloadAction<number>) => {
            state.totalSteps = action.payload;
        },

        updateCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },

        saveCurrentStepAnswer: (state, action: PayloadAction<SurveyAnswer>) => {
            state.surveyAnswers.push(action.payload);
        },

        updateCurrentStepAnswer: (state, action: PayloadAction<Answer>) => {
            state.stepSurveyQuestions[state.currentStep - 1].answer =
                action.payload;
        },

        clearReduxState: (state) => {
            state.currentStep = 1;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getBrandDetailsAsync.pending, (state) => {
                state.brandDataLoading = true;
            })
            .addCase(getBrandDetailsAsync.fulfilled, (state, { payload }) => {
                state.brandDataLoading = false;
                state.brandDetails = payload.data;
            })
            .addCase(getBrandDetailsAsync.rejected, (state) => {
                state.brandDataLoading = false;
            });
    },
});

export const {
    updateAllQuestions,
    updateStepSurveyQuestions,
    initializeFormData,
    updateTotalSteps,
    updateCurrentStep,
    saveCurrentStepAnswer,
    updateCurrentStepAnswer,
    clearReduxState,
} = surveyV4Slice.actions;

export default surveyV4Slice.reducer;
