import { BrandDetails } from '@models/brand-details';
import { QuestionPages } from '@models/brand-questionaire';
import { SurveyAnswer, SurveyStepQuestions } from '@models/survey';

export interface FromDataValue {
    selectedAnswer?: string;
    answer?: string;
    selectedAnswers?: string[];
}

interface FileState {
    id: string;
    blob: Blob;
}

export interface InitialState {
    brandDataLoading: boolean;
    questionnaireLoading: boolean;
    brandDetails: BrandDetails | null;
    allQuestions: QuestionPages | null;
    stepSurveyQuestions: SurveyStepQuestions[];
    totalSteps: number | null;
    currentStep: number | null;
    isCurrentStepRequired: boolean;
    surveyAnswers: SurveyAnswer[];
    formData: { [key: number]: any }[];
    selectedMultimediaOption: 'audio' | 'video' | 'images';
    audioBlob: FileState | null;
    videoBlob: FileState | null;
    imagesBlob: null;
}

const v4InitialState: InitialState = {
    brandDataLoading: false,
    allQuestions: null,
    questionnaireLoading: false,
    brandDetails: null,
    stepSurveyQuestions: [],
    totalSteps: null,
    currentStep: 1,
    isCurrentStepRequired: false,
    // fromData: null,
    surveyAnswers: [],
    formData: [],
    selectedMultimediaOption: null,
    audioBlob: null,
    videoBlob: null,
    imagesBlob: null,
};

export default v4InitialState;
