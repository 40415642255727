import { useAppSelector } from '@hooks/useRedux';
import { getV4SurveyState } from '@redux/v4-survey/selector';
import React from 'react';

interface RatingSectionProps {
    currentStep: number;
    text: string;
    mintext: string;
    maxtext: string;
    minscale: number;
    maxscale: number;
    onRadioChange: (value: string) => void;
    selectedValue: string;
    required: boolean;
}

const RatingSection: React.FC<RatingSectionProps> = ({
    currentStep,
    text,
    mintext,
    maxtext,
    minscale,
    maxscale,
    onRadioChange,
    selectedValue,
    required,
}) => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onRadioChange(event.target.value);
    };

    return (
        <section>
            <h3>
                <span
                    className={
                        currentStep.toString().length > 1
                            ? 'number two-digit'
                            : 'number'
                    }
                >
                    {currentStep}
                </span>
                {text} {required && <>*</>}
            </h3>
            <div className="likely-box">
                {[...Array(maxscale - minscale + 1)].map((_, index) => {
                    const value = minscale + index;
                    return (
                        <label className="radio-group" key={value}>
                            {value === minscale && (
                                <span className="likely-link">{mintext}</span>
                            )}

                            {value === maxscale && (
                                <span className="likely-link link-right">
                                    {maxtext}
                                </span>
                            )}

                            <input
                                type="radio"
                                name="number"
                                value={value}
                                checked={selectedValue === value.toString()}
                                onChange={handleRadioChange}
                            />
                            <span>{value}</span>
                        </label>
                    );
                })}
            </div>
        </section>
    );
};

export default RatingSection;
