import { UI_TEXT } from '@constants/ui-text';
const { POWERED_BY, TELLOFY } = UI_TEXT;

const PoweredByTellofy = () => {
    return (
        <div className="col-12 col-sm-auto">
            <span className="copywright">
                {POWERED_BY} &nbsp;
                <a
                    href="https://www.tellofy.com/"
                    target="_blank"
                    className="link-brand"
                    rel="noreferrer"
                >
                    {TELLOFY}
                </a>
            </span>
        </div>
    );
};

export default PoweredByTellofy;
