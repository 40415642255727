import { FC } from 'react';

interface ProgressBarProps {
    currentStep: number;
    totalSteps: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
    const percentage = (currentStep / totalSteps) * 100;

    return (
        <div className="progress-line">
            <div
                className="progress"
                style={{ width: `${percentage}%`, borderRadius: 0 }}
            />
        </div>
    );
};

export default ProgressBar;
