const SURVEY_V4 = 'survey-v4';
const TEMP_MEDIA_UPLOAD = 'temp-media-upload';

export const BRAND = {
    DETAILS: `/${SURVEY_V4}/brand-details`,
    QUESTIONNAIRE: `/${SURVEY_V4}/questionnaire`,
    POST_SURVEY_DATA: `${SURVEY_V4}/post-survey-to-groovy`,
};

export const MULTIMEDIA_UPLOAD = {
    AUDIO: `/${TEMP_MEDIA_UPLOAD}/audio/upload`,
    VIDEO: `/${TEMP_MEDIA_UPLOAD}/video/upload`,
    IMAGE_FILES: `/${TEMP_MEDIA_UPLOAD}/files/upload`,
    SPEECH_TO_TEXT: `${TEMP_MEDIA_UPLOAD}/speech-to-text`,
    AUDIO_TO_TEXT: `${TEMP_MEDIA_UPLOAD}/audio-to-text`,
};

export const PROD_IMAGE_PATH = {
    MAIN: 'https://www.tellofy.com/_next/image?url=https://app.tellofy.com/TellofyApi/download/',
    SIZE: '&w=128&q=75',
};

export const REDIRECT_ULS = {
    FEEDBACK_V4: 'feedback/V4',
    FEEDBACK_URL: 'feedbackUrl',
};
