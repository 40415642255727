import { Element, Page, QuestionPages } from '@models/brand-questionaire';
import { SurveyStepQuestions } from '@models/survey';
export const formatSurveyQuestions = (data: QuestionPages) => {
    let step = 1;
    const formattedQuestions: SurveyStepQuestions[] = [];
    const initialValues: { [key: string]: any }[] = [];

    const getDefaultAnswer = (type: string) => {
        switch (type) {
            case 'nps':
                return { selectedAnswer: '' }; // text
            case 'npstext':
                return { answer: '' }; // text
            case 'text':
                return { answer: '' }; // text
            case 'checkbox':
                return { selectedAnswers: [] }; // answer ids
            case 'time':
                return { answer: '' }; // text
            case 'scale':
                return { selectedAnswer: '' }; //text
            case 'radio':
                return { selectedAnswer: '' }; //answerid
            case 'select':
                return { selectedAnswer: '' }; //answerid
            case 'date':
                return { answer: '' }; //text
        }
    };

    data.pages.forEach((page: Page) => {
        page.elements.forEach((element: Element, index: number) => {
            const question = element.question;

            const formattedQuestion: SurveyStepQuestions = {
                id: question.id,
                type: question.type,
                text: question.text,
                required: question.required,
                department: question.department,
                offeredAnswers: question.offeredAnswers,
                minscale: question.minscale,
                maxscale: question.maxscale,
                mintext: question.mintext,
                maxtext: question.maxtext,
                step,
                image: question.image1,
                answer: getDefaultAnswer(question.type),
                questionOrder: element.orderNo, // Attach the question order
            };

            if (index === 0) {
                formattedQuestion.name = page.name;
                formattedQuestion.pageSubtitle = page.pageSubtitle;
            }

            switch (question.type) {
                case 'text':
                case 'radio':
                case 'select':
                case 'npstext':
                    initialValues.push({ [question.id]: '' });
                    break;
                case 'checkbox':
                    initialValues.push({ [question.id]: [] });
                    break;
                case 'date':
                case 'time':
                    initialValues.push({ [question.id]: '' });
                    break;
                case 'scale':
                case 'nps':
                    initialValues.push({ [question.id]: '' });
                    break;
                default:
                    initialValues.push({ [question.id]: '' });
                    initialValues[question.id] = '';
            }

            formattedQuestions.push(formattedQuestion);
            step++;
        });
    });

    return { formattedQuestions, initialValues };
};
