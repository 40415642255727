import { SurveyStepQuestions } from '@models/survey';

interface DetailedReview {
    image: string[];
    questionid: string;
    answer?: string;
    selectedAnswers?: string[];
    departmentRefID?: string;
    selectedAnswer?: string;
}
export const formatQuesRes = (surveyQuestions: SurveyStepQuestions[]) => {
    const detailedReview: DetailedReview[] = [];
    surveyQuestions.forEach((question, index) => {
        // Format for detailedReview
        const review: DetailedReview = {
            image: [],
            questionid: question.id,
        };

        if (question.answer.selectedAnswer) {
            review.selectedAnswer = question.answer.selectedAnswer;
        }
        if (question.answer.answer) {
            review.answer = question.answer.answer;
        }
        if (question.answer.selectedAnswers) {
            review.selectedAnswers = question.answer.selectedAnswers;
        }
        if (question.department) {
            review.departmentRefID = question.department;
        }
        detailedReview.push(review);
    });

    return detailedReview;
};
