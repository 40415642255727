import { useAppSelector } from '@hooks/useRedux';
import { Answer, SurveyStepQuestions } from '@models/survey';
import { getV4SurveyState } from '@redux/v4-survey/selector';
import { Field, useField } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import RatingSection from './common/RatingSection';

interface CustomQuestionProps {
    question: SurveyStepQuestions;
    getCurrentStepFilledAnswer: (
        filledAnswer: Answer,
        question: SurveyStepQuestions
    ) => void;
}

const imageBaseUrl = process.env.REACT_APP_QUESTION_IMAGE_BASE_URL;

const CustomQuestion: FC<CustomQuestionProps> = ({
    question,
    getCurrentStepFilledAnswer,
}) => {
    const {
        surveyV4: { stepSurveyQuestions, currentStep },
    } = useAppSelector(getV4SurveyState);

    const [localAnswers, setLocalAnswers] = useState<Answer>(question.answer);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, type } = e.target;

        const answerTypes = ['text', 'time', 'date'];
        if (answerTypes.includes(type)) {
            setLocalAnswers({ answer: value });
            getCurrentStepFilledAnswer({ answer: value }, question);
        }
    };

    const [field, meta, helpers] = useField(question.id);

    const handleCheckboxChange = (optionId: string) => {
        const set = new Set<string>(field.value);
        if (set.has(optionId)) {
            set.delete(optionId);
        } else {
            set.add(optionId);
        }

        helpers.setValue(Array.from(set));

        setLocalAnswers({ selectedAnswers: [...set] });
        getCurrentStepFilledAnswer({ selectedAnswers: [...set] }, question);
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setLocalAnswers({ selectedAnswer: value });
        getCurrentStepFilledAnswer({ selectedAnswer: value }, question);
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setLocalAnswers({ selectedAnswer: value });
        getCurrentStepFilledAnswer({ selectedAnswer: value }, question);
    };

    const handleScaleChange = (value: string) => {
        setLocalAnswers({ selectedAnswer: value });
        getCurrentStepFilledAnswer({ selectedAnswer: value }, question);
    };

    const setCurrentStepPreFilledValue = () => {
        switch (question.type) {
            case 'text':
            case 'time':
            case 'date':
                setLocalAnswers(question.answer);
                return {
                    answer: stepSurveyQuestions[currentStep - 1].answer.answer,
                };
            case 'scale':
            case 'radio':
            case 'nps':
            case 'select':
                setLocalAnswers(question.answer);
                return {
                    selectedAnswer:
                        stepSurveyQuestions[currentStep - 1].answer
                            .selectedAnswer,
                };
            case 'checkbox':
                setLocalAnswers(question.answer);
                return {
                    selectedAnswers:
                        stepSurveyQuestions[currentStep - 1].answer
                            .selectedAnswers,
                };
        }
    };

    useEffect(() => {
        getCurrentStepFilledAnswer(setCurrentStepPreFilledValue(), question);
    }, [currentStep]);

    switch (question.type) {
        case 'text':
            return (
                <>
                    <h3>
                        <span
                            className={
                                currentStep.toString().length > 1
                                    ? 'number two-digit'
                                    : 'number'
                            }
                        >
                            {question.step}
                        </span>{' '}
                        {question.text} {question.required && <>*</>}
                    </h3>

                    <div className="form-group">
                        {/* TODO render the question image here  */}
                        {/* {question.image && (
                            <img
                                alt="question_image"
                                height={100}
                                width={100}
                                src={
                                    'https://app.tellofy.com/TellofyApi/download/Temp/questionimageupload/667babfbe4b0a3f575b55db0'
                                }
                            />
                        )} */}
                        <Field
                            name={question.id}
                            type={question.type}
                            placeholder={'ex. Value'}
                            className="form-control input-small"
                            value={localAnswers.answer}
                            defaultValue={localAnswers.answer}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </>
            );
        case 'time':
            return (
                <>
                    <h3>
                        <span
                            className={
                                currentStep.toString().length > 1
                                    ? 'number two-digit'
                                    : 'number'
                            }
                        >
                            {question.step}
                        </span>{' '}
                        {question.text} {question.required && <>*</>}
                    </h3>
                    <div className="form-group input-small">
                        {question.image && (
                            <img
                                alt="question_image"
                                height={100}
                                src={imageBaseUrl + question.image}
                            />
                        )}
                        <Field
                            name={question.id}
                            type="time"
                            placeholder={question.text}
                            className="form-control"
                            value={localAnswers.answer}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                    </div>
                </>
            );
        case 'date':
            return (
                <>
                    <h3>
                        <span
                            className={
                                currentStep.toString().length > 1
                                    ? 'number two-digit'
                                    : 'number'
                            }
                        >
                            {question.step}
                        </span>{' '}
                        {question.text} {question.required && <>*</>}
                    </h3>
                    <div className="form-group input-small">
                        {question.image && (
                            <img
                                alt="question_image"
                                height={100}
                                src={imageBaseUrl + question.image}
                            />
                        )}
                        <Field
                            name={question.id}
                            type="date"
                            placeholder={question.text}
                            className="form-control"
                            value={localAnswers.answer}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                    </div>
                </>
            );
        case 'radio':
            return (
                <>
                    <h3>
                        <span
                            className={
                                currentStep.toString().length > 1
                                    ? 'number two-digit'
                                    : 'number'
                            }
                        >
                            {question.step}
                        </span>{' '}
                        {question.text} {question.required && <>*</>}
                    </h3>
                    <div className="form-group">
                        <div>
                            {question.offeredAnswers?.map((option: any) => (
                                <div key={option.id}>
                                    <label className="label-store">
                                        <Field
                                            type="radio"
                                            className="form-control"
                                            name={question.id}
                                            value={option.id}
                                            checked={
                                                localAnswers.selectedAnswer ===
                                                option.id
                                            }
                                            onChange={(e) =>
                                                handleRadioChange(e)
                                            }
                                        />
                                        <span className="align-middle f"></span>
                                        <span className="align-middle ms-1"></span>
                                        {option.value}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            );
        case 'checkbox':
            return (
                <>
                    <h3>
                        <span
                            className={
                                currentStep.toString().length > 1
                                    ? 'number two-digit'
                                    : 'number'
                            }
                        >
                            {question.step}
                        </span>{' '}
                        {question.text} {question.required && <>*</>}
                    </h3>
                    <div className="form-group">
                        <div>
                            <div className="check-flex">
                                {question.offeredAnswers?.map((option: any) => (
                                    <div key={option.id}>
                                        <label className="label-store">
                                            <Field
                                                type="checkbox"
                                                className="form-control"
                                                name={question.id}
                                                value={option.id}
                                                checked={
                                                    localAnswers &&
                                                    localAnswers.selectedAnswers &&
                                                    localAnswers.selectedAnswers
                                                        .length > 0 &&
                                                    localAnswers.selectedAnswers.includes(
                                                        option.id
                                                    )
                                                }
                                                onChange={(e) =>
                                                    handleCheckboxChange(
                                                        option.id
                                                    )
                                                }
                                            />
                                            <span className="align-middle f"></span>
                                            <span className="align-middle ms-1"></span>
                                            {option.value}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            );
        case 'select':
            return (
                <>
                    <h3>
                        <span
                            className={
                                currentStep.toString().length > 1
                                    ? 'number two-digit'
                                    : 'number'
                            }
                        >
                            {question.step}
                        </span>{' '}
                        {question.text} {question.required && <>*</>}
                    </h3>
                    <div className="form-group select-box">
                        <Field
                            name={question.id}
                            className="form-control input-small select-input"
                            as="select"
                            value={localAnswers.selectedAnswer}
                            onChange={(e) => handleSelectChange(e)}
                        >
                            <option value="" label="Search or Select" />
                            {question.offeredAnswers?.map((option: any) => (
                                <>
                                    {localAnswers.selectedAnswer} {option.id}
                                    <option key={option.id} value={option.id}>
                                        {option.value}
                                    </option>
                                </>
                            ))}
                        </Field>
                    </div>
                </>
            );
        case 'scale':
            return (
                <RatingSection
                    currentStep={currentStep}
                    selectedValue={localAnswers.selectedAnswer}
                    text={question.text}
                    mintext={question.mintext}
                    maxtext={question.maxtext}
                    minscale={question.minscale}
                    maxscale={question.maxscale}
                    onRadioChange={handleScaleChange}
                    required={question.required}
                />
            );
        default:
            return null;
    }
};

export default CustomQuestion;
