import { FC, useEffect, useRef, useState } from 'react';
import { TEXT } from '@constants/messages';
import { SelectedMediaType } from '@models/media';
import useDialog from '@hooks/useDialog';
import { createPortal } from 'react-dom';
import ConfirmationModal from '@components/UI/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faVolumeMute,
    faVolumeDown,
    faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
const {
    PERMISSIONS_NOT_ALLOWED,
    NO_MEDIA_STREAM_AVAILABLE,
    ERROR_STARTING_MEDIA_RECORDER,
    DELETE_VIDEO,
    DELETE_VIDEO_BODY,
} = TEXT;

interface CustomVideoProps {
    videoBlob: Blob | null;
    setVideoBlob: React.Dispatch<React.SetStateAction<Blob>>;
    setSelectedMediaType: React.Dispatch<
        React.SetStateAction<SelectedMediaType>
    >;
    setSelectedMediaOption: React.Dispatch<
        React.SetStateAction<SelectedMediaType>
    >;
    setShowMediaAttachedText: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomVideo: FC<CustomVideoProps> = ({
    videoBlob,
    setVideoBlob,
    setSelectedMediaType,
    setSelectedMediaOption,
    setShowMediaAttachedText,
}) => {
    const intervalRef = useRef(null);
    const [localVideoBlob, setLocalVideoBlob] = useState<Blob | null>(null);
    const { isOpen, showDialog, dialogData, hideDialog } = useDialog(false);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
        null
    );
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>(
        'user'
    );
    const [error, setError] = useState<string | null>(null);
    const [recordingTime, setRecordingTime] = useState<number>(0);
    const [isRecording, setIsRecording] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const recordedVideoRef = useRef<HTMLVideoElement | null>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const volumeSliderRef = useRef(null);
    const [volume, setVolume] = useState(1);
    const [_isPlaying, setIsPlaying] = useState(false);

    const MAX_RECORDING_TIME = 2 * 60; // 2 mints

    const getUserMedia = async (facingMode: 'user' | 'environment') => {
        try {
            setError(null);
            const constraints = {
                audio: true,
                video: {
                    facingMode,
                    width: { min: 640, ideal: 640, max: 640 },
                    height: { min: 480, ideal: 480, max: 480 },
                    frameRate: 60,
                },
            };

            const stream =
                await navigator.mediaDevices.getUserMedia(constraints);
            setStream(stream);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (err) {
            setError(PERMISSIONS_NOT_ALLOWED);
        }
    };

    const handleStartRecording = () => {
        if (stream) {
            try {
                const mediaRecorder = new MediaRecorder(stream);
                setMediaRecorder(mediaRecorder);
                setIsRecording(true);
                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        console.log('event.data.size >> ', event.data.size);

                        const blob = new Blob([event.data], {
                            type: 'video/mp4',
                        });

                        setVideoBlob(blob);
                    }
                };
                mediaRecorder.start();

                mediaRecorder.onstop = () => {
                    console.log('onstop');
                    setMediaRecorder(null);
                };

                setRecordingTime(0);
                intervalRef.current = window.setInterval(() => {
                    setRecordingTime((prev) => {
                        if (prev >= MAX_RECORDING_TIME - 1) {
                            clearInterval(intervalRef.current);
                            return prev + 1;
                        }
                        return prev + 1;
                    });
                }, 1000);

                setTimeout(() => {
                    if (mediaRecorder.state === 'recording') {
                        mediaRecorder.stop();
                    }
                }, MAX_RECORDING_TIME * 1000);
            } catch (err) {
                setError(ERROR_STARTING_MEDIA_RECORDER);
            }
        } else {
            setError(NO_MEDIA_STREAM_AVAILABLE);
        }
    };

    const handleStopRecording = () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
        }

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        mediaRecorder.stop();
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null); // Reset the stream state
        }
        setMediaRecorder(null);
        setIsRecording(false);
        setRecordingTime(0);
    };

    const handleSwitchCamera = async () => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
        setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'));
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleDeleteVideo = () => {
        showDialog<{ heading: string; body: string }>({
            heading: DELETE_VIDEO,
            body: DELETE_VIDEO_BODY,
        });
    };

    const deleteVideo = () => {
        setLocalVideoBlob(null);
        setVideoBlob(null);
        setSelectedMediaType(null);
        setSelectedMediaOption(null);
    };

    const renderDeleteVideoDialog = () => {
        return isOpen
            ? createPortal(
                  <ConfirmationModal
                      onSuccess={deleteVideo}
                      onCancel={onCancelDelete}
                      dialogData={dialogData}
                  />,
                  document.body
              )
            : null;
    };

    const onCancelDelete = () => {
        hideDialog();
    };

    useEffect(() => {
        if (videoBlob) {
            setSelectedMediaType('video');
        } else {
            setSelectedMediaType(null);
        }
        setLocalVideoBlob(videoBlob);
    }, [videoBlob]);

    useEffect(() => {
        setShowMediaAttachedText(false);
        return () => {
            setShowMediaAttachedText(true);
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        getUserMedia(facingMode);
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [facingMode]);

    useEffect(() => {
        if (recordedVideoRef.current) {
            recordedVideoRef.current.volume = volume;
        }
    }, []);

    return (
        <div className="video-outer">
            {isOpen && renderDeleteVideoDialog()}
            {!localVideoBlob && (
                <div className="video-recording">
                    <div className="video-js">
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted
                            controls={false}
                            className="vjs-tech"
                        ></video>
                    </div>
                    {isRecording && (
                        <div className="vjs-record-indicator"></div>
                    )}
                    <div className="vjs-control-bar">
                        <div className="left-icons">
                            {!isRecording && (
                                <button
                                    onClick={() => handleStartRecording()}
                                    disabled={isRecording}
                                    className="start-video"
                                >
                                    <div className="start-video-icon">
                                        &nbsp;
                                    </div>
                                </button>
                            )}
                            {isRecording && (
                                <button
                                    onClick={() => handleStopRecording()}
                                    disabled={!isRecording}
                                    className="stop-video"
                                >
                                    <div className="stop-video-icon">
                                        &nbsp;
                                    </div>
                                </button>
                            )}

                            <div>{formatTime(recordingTime)} / 2:00</div>
                        </div>
                        <div className="right-icons">
                            <button className="full-screen">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                >
                                    <g
                                        id="fullscreen"
                                        transform="translate(-5 -5)"
                                    >
                                        <path
                                            id="Path_3257"
                                            data-name="Path 3257"
                                            d="M7,14H5v5h5V17H7ZM5,10H7V7h3V5H5Zm12,7H14v2h5V14H17ZM14,5V7h3v3h2V5Z"
                                            fill="#a187e7"
                                        />
                                    </g>
                                </svg>
                            </button>

                            <button
                                onClick={() => handleSwitchCamera()}
                                disabled={isRecording}
                                className="switch-camera"
                            >
                                <svg
                                    width="25px"
                                    height="25px"
                                    viewBox="0 0 48 48"
                                    version="1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    enableBackground="new 0 0 48 48"
                                >
                                    <g fill="#440fcf">
                                        <path d="M33.9,12.1H14.2L17.6,7c0.4-0.6,1-0.9,1.7-0.9h9.6c0.7,0,1.3,0.3,1.7,0.9L33.9,12.1z" />
                                        <path d="M14,11H8V9.2C8,8.5,8.5,8,9.2,8h3.6C13.5,8,14,8.5,14,9.2V11z" />
                                        <path d="M40,42H8c-2.2,0-4-1.8-4-4V14c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v24C44,40.2,42.2,42,40,42z" />
                                    </g>
                                    <path
                                        fill="#E8EAF6"
                                        d="M34,25c0-5.5-4.5-10-10-10c-2.4,0-4.6,0.8-6.3,2.2l1.2,1.6c1.4-1.1,3.1-1.8,5.1-1.8c4.4,0,8,3.6,8,8h-3.5 l4.5,5.6l4.5-5.6H34z"
                                    />
                                    <path
                                        fill="#E8EAF6"
                                        d="M29.1,31.2C27.7,32.3,25.9,33,24,33c-4.4,0-8-3.6-8-8h3.5L15,19.4L10.5,25H14c0,5.5,4.5,10,10,10 c2.4,0,4.6-0.8,6.3-2.2L29.1,31.2z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {localVideoBlob && (
                <div
                    className="video-delete"
                    onClick={() => handleDeleteVideo()}
                >
                    <svg
                        width="13"
                        height="15"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.00018 18C2.45018 18 1.97935 17.8042 1.58768 17.4125C1.19602 17.0208 1.00018 16.55 1.00018 16V3H0.000183105V1H5.00018V0H11.0002V1H16.0002V3H15.0002V16C15.0002 16.55 14.8043 17.0208 14.4127 17.4125C14.021 17.8042 13.5502 18 13.0002 18H3.00018ZM5.00018 14H7.00018V5H5.00018V14ZM9.00018 14H11.0002V5H9.00018V14Z"
                            fill="#AF91FF"
                        />
                    </svg>
                </div>
            )}
            {localVideoBlob && (
                <>
                    <div className="video-preview">
                        <video
                            ref={recordedVideoRef}
                            src={
                                localVideoBlob
                                    ? URL.createObjectURL(localVideoBlob)
                                    : ''
                            }
                            controls={true}
                        ></video>
                    </div>
                </>
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default CustomVideo;
