import modalMic from 'img/modal-mic.svg';
import { FC, useEffect } from 'react';

interface SpeechToTextModalProps {
    message: string;
    timeoutMethod?: () => void;
}

const SpeechToTextModal: FC<SpeechToTextModalProps> = ({
    message,
    timeoutMethod,
}) => {
    useEffect(() => {
        if (timeoutMethod) {
            const timeout = setTimeout(() => {
                timeoutMethod();
            }, 2000);

            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeout);
        }
    }, [timeoutMethod]);

    return (
        <div
            className="modal modal-audio fade show"
            id="modalaudio"
            tabIndex={-1}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="icon-box">
                        <div className="icon-box-inside">
                            <img src={modalMic} alt="" width="64" />
                        </div>
                    </div>
                    <h4>{message}</h4>
                </div>
            </div>
        </div>
    );
};

export default SpeechToTextModal;
