import axios, { AxiosError, AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

/* The `axiosInstance.interceptors.request.use()` function is adding a request interceptor to the Axios
instance. This interceptor is used to modify the outgoing request before it is sent.. */

axiosInstance.interceptors.request.use(
    (req) => {
        const X_ACCESS_KEY = process.env.REACT_APP_X_ACCESS_ENCRYPTED_API_KEY;
        if (X_ACCESS_KEY && X_ACCESS_KEY.trim() !== '') {
            req.headers['x-api-key'] = X_ACCESS_KEY;
        }
        return req;
    },
    (error: AxiosError) => {
        return Promise.reject(error.message);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    (error: AxiosError) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Handle errors globally
        console.error('Response error:', error);
        return Promise.reject(error.response.data);
    }
);

export default axiosInstance;
