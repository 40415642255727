import axiosInstance from 'api/api-service';
import { HttpResponseType } from '@models/http-interface';
import { BrandDetails } from '@models/brand-details';
import { BRAND, MULTIMEDIA_UPLOAD } from '@constants/api-endpoints';
import {
    AudioUploadResponse,
    BrandQuestionnaire,
    ImageUploadResponse,
    VideoUploadResponse,
} from '@models/brand-questionaire';

const { DETAILS, QUESTIONNAIRE, POST_SURVEY_DATA } = BRAND;
const { IMAGE_FILES, AUDIO, VIDEO, AUDIO_TO_TEXT } = MULTIMEDIA_UPLOAD;

// get brand details using feedbackUrl
export const getBrandDetails = (feedbackUrl: string) => {
    const url = { feedbackUrl };
    return axiosInstance.get<HttpResponseType<BrandDetails>>(DETAILS, {
        params: url,
    });
};

export const getBrandQuestionnaires = (questionId: string) => {
    return axiosInstance.get<HttpResponseType<BrandQuestionnaire>>(
        `${QUESTIONNAIRE}/${questionId}`
    );
};

export const uploadTemporaryImages = (formData: FormData) => {
    return axiosInstance.post<HttpResponseType<ImageUploadResponse>>(
        `${IMAGE_FILES}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
};

export const uploadTemporaryAudio = (formData: FormData) => {
    return axiosInstance.post<HttpResponseType<AudioUploadResponse>>(
        `${AUDIO}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
};

export const uploadTemporaryVideo = (fromData: FormData) => {
    return axiosInstance.post<HttpResponseType<VideoUploadResponse>>(
        `${VIDEO}`,
        FormData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
};

export const speechToText = (formData: FormData) => {
    return axiosInstance.post<HttpResponseType<string>>(
        `${AUDIO_TO_TEXT}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
};

export const postSurveyDataFromV4 = (url, formData, headers) => {
    return axiosInstance.post<HttpResponseType<any>>(`${POST_SURVEY_DATA}`, {
        url,
        headers,
        formData,
    });
};
