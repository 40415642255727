import deleteBig from 'img/delete-big.svg';
import { UI_TEXT } from '@constants/ui-text';

const { YES_DELETE, NO_CANCEL } = UI_TEXT;

interface ConfirmationModalProps<
    T extends { heading?: string; body?: string },
> {
    onSuccess: () => void;
    onCancel: () => void;
    dialogData?: T;
}

const ConfirmationModal = <T extends { heading?: string; body?: string }>({
    onSuccess,
    onCancel,
    dialogData,
}: ConfirmationModalProps<T>) => {
    return (
        <>
            <div
                className="modal modal-delete fade show"
                id="confirmDeleteModal"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="icon-box">
                            <div className="icon-box-inside">
                                <img src={deleteBig} alt="" />
                            </div>
                        </div>
                        <h3 className="mb-2">
                            {dialogData.heading && <>{dialogData.heading}</>}
                        </h3>
                        <p className="fz-14 mb-4">
                            {dialogData.body && <> {dialogData.body}</>}
                        </p>
                        <div className="row row-8 align-items-center justify-content-between">
                            <div className="col col-sm-auto">
                                <button
                                    className="btn btn-next btn-block delete-image-btn"
                                    onClick={() => onSuccess()}
                                >
                                    {YES_DELETE}
                                </button>
                            </div>
                            <div className="col col-sm-auto">
                                <button
                                    className="btn btn-next btn-block btn-cancel"
                                    data-bs-dismiss="modal"
                                    onClick={() => onCancel()}
                                >
                                    {NO_CANCEL}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmationModal;
