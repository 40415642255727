import { useState } from 'react';

const useDialog = (initialState: boolean) => {
    const [isOpen, setOpen] = useState(initialState);
    const [dialogData, setData] = useState(null);

    const showDialog = <T>(obj: T) => {
        setOpen(true);
        setData(obj);
    };

    const hideDialog = () => {
        setOpen(false);
        setData(null);
    };

    return { isOpen, showDialog, hideDialog, dialogData };
};

export default useDialog;
