import { DetailedAnswer } from '@models/brand-questionaire';

export const formatSurveyAnswers = (questions) => {
    let result = {};

    questions.forEach((question) => {
        // Initialize the answer object with common properties
        let answerObj: DetailedAnswer = {
            image: [],
            questionid: question.id,
        };

        // Add 'answer', 'selectedAnswer', or 'selectedAnswers' based on the type of question
        if (question.answer.hasOwnProperty('answer')) {
            answerObj.answer = question.answer.answer;
        } else if (question.answer.hasOwnProperty('selectedAnswer')) {
            answerObj.selectedAnswer = question.answer.selectedAnswer;
        } else if (question.answer.hasOwnProperty('selectedAnswers')) {
            answerObj.selectedAnswers = question.answer.selectedAnswers;
        }

        // Include department reference ID if available
        if (question.department) {
            answerObj.departmentRefID = question.department;
        }

        // Assign the formatted object to the result under the question's ID
        result[question.id] = answerObj;
    });

    return JSON.stringify(result);
};
