import { FC, useEffect, useRef } from 'react';
import thankyou from 'img/thankyou.svg';
import confetti from 'canvas-confetti';
import Header from './Header';
import { useAppDispatch, useAppSelector } from '@hooks/useRedux';
import { getBrandDetails, getTotalSteps } from '@redux/v4-survey/selector';
import ThankyouFooter from './UI/ThankyouFooter';
import { clearReduxState } from '@redux/v4-survey/slice';
import ProgressBar from './common/ProgressBar';
import { UI_TEXT } from '@constants/ui-text';
import { REDIRECT_ULS } from '@constants/api-endpoints';

const { FEEDBACK_V4, FEEDBACK_URL } = REDIRECT_ULS;

const { YOUR_FEEDBACK_IS_VERY_VALUABLE } = UI_TEXT;

const Thankyou: FC = () => {
    const confettiRef = useRef(null);

    const brandDetails = useAppSelector(getBrandDetails);

    if (brandDetails == null) {
        window.location.assign(
            `${process.env.REACT_APP_BASE_URL}/${FEEDBACK_V4}/${localStorage.getItem(FEEDBACK_URL)}`
        );
    }
    const totalSteps = useAppSelector(getTotalSteps);

    const dispatch = useAppDispatch();

    const clearRedux = () => {
        dispatch(clearReduxState());
    };

    let end = Date.now() + 30 * 1000;

    let animationFrameId: number;

    const frame = () => {
        confetti({
            particleCount: 7,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
        });
        confetti({
            particleCount: 7,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
        });

        if (Date.now() < end) {
            animationFrameId = requestAnimationFrame(frame);
        }
    };

    useEffect(() => {
        frame();
        return () => {
            // Stop the confetti animation
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }

            clearRedux();
            confetti.reset();
        };
    }, []);

    return (
        <>
            {totalSteps && (
                <ProgressBar currentStep={totalSteps} totalSteps={totalSteps} />
            )}

            {brandDetails != null && (
                <Header
                    brandName={brandDetails.name}
                    brandIcon={
                        brandDetails.imagePaths &&
                        brandDetails.imagePaths.length &&
                        brandDetails.imagePaths.length > 0
                            ? brandDetails.imagePaths[0]
                            : null
                    }
                />
            )}

            <div className="main-section container">
                <div className="row flex-grow-1">
                    <div className="col-2 d-none d-lg-block"></div>
                    <div className="col steps">
                        <div
                            className="main-content"
                            style={{ paddingTop: '100px' }}
                        >
                            <div className="row">
                                <div className="col-xl-8">
                                    <section>
                                        <div className="feedback-box">
                                            <img src={thankyou} alt="" />
                                        </div>
                                        <div className="title-feedback mt-4">
                                            {YOUR_FEEDBACK_IS_VERY_VALUABLE}
                                        </div>
                                        <canvas
                                            ref={confettiRef}
                                            style={{
                                                position: 'fixed',
                                                width: '100%',
                                                height: '100%',
                                                pointerEvents: 'none',
                                                left: '0',
                                                top: '0',
                                                zIndex: '100',
                                            }}
                                        />
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ThankyouFooter />
        </>
    );
};

export default Thankyou;
